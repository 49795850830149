import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../assets/js/USStateEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import Select from '../../../components/UI/Form/Select/Select';
import {
  COAPPCITY_TOOLTIP_TEXT,
  COAPPSTATE_TOOLTIP_TEXT,
  COAPPSTREET_TOOLTIP_TEXT,
  COAPPZIPCODE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { isStateAllowed, reactGAEvent, reactGAPageview } from '../../../helper';
import { changeMicrofInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import { MicrofFlowPageNumber } from '../MicrofFlow';

const CoAppAddress = props => {
  const {
    microfInformation,
    dispatchChangeInfoValue,
    pqi,
    dispatchChangePage,
    children,
    aquaInformation,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_coappinfo2',
      trackerNames: [],
      title: 'ca_coappinfo2',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const setIsSameAddress = bool => {
    dispatchChangeInfoValue('isSameAddress', bool);
  };

  const CoAppAddressForm = () => (
    <Form>
      <Label>
        <b>The address is the same as the applicant's.</b>
      </Label>
      <Row>
        <Col sm="6">
          <Button
            className={microfInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => setIsSameAddress(true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!microfInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => setIsSameAddress(false)}
          />
        </Col>
      </Row>
      <Field
        disabled={microfInformation.isSameAddress}
        component={Input}
        label="Street"
        name="coAppStreet"
        id="coAppStreet"
        type="text"
        tooltipText={COAPPSTREET_TOOLTIP_TEXT}
      />
      <Row>
        <Col sm="6">
          <Field
            disabled={microfInformation.isSameAddress}
            component={Input}
            label="City"
            name="coAppCity"
            id="coAppCity"
            type="text"
            tooltipText={COAPPCITY_TOOLTIP_TEXT}
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={microfInformation.isSameAddress}
            component={Select}
            label="State"
            name="coAppState"
            id="coAppState"
            type="select"
            tooltipText={COAPPSTATE_TOOLTIP_TEXT}
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        disabled={microfInformation.isSameAddress}
        component={Input}
        label="ZIP Code"
        name="coAppZipCode"
        id="coAppZipCode"
        tooltipText={COAPPZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />

      <Button type="submit" title="Continue" />
    </Form>
  );

  const CoAppAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppStreet:
        microfInformation.isSameAddress && pqi.street
          ? pqi.street
          : microfInformation.coAppStreet || aquaInformation.coAppStreet || '',
      coAppCity:
        microfInformation.isSameAddress && pqi.city
          ? pqi.city
          : microfInformation.coAppCity || aquaInformation.coAppCity || '',
      coAppZipCode:
        microfInformation.isSameAddress && pqi.zipcode
          ? pqi.zipcode
          : microfInformation.coAppZipCode || aquaInformation.coAppZipCode || '',
      coAppState:
        microfInformation.isSameAddress && pqi.state
          ? pqi.state
          : microfInformation.coAppState || aquaInformation.coAppState || '',
    }),

    validationSchema: Yup.object({
      coAppStreet: Yup.string()
        .matches(/.*([a-zA-Z].*[0-9]|[0-9].*[a-zA-Z]).*/, {
          message: 'The street field must contain the street name and number',
          excludeEmptyString: true,
        })
        .required('Please enter a street.'),
      coAppCity: Yup.string().required('Please enter a city.'),
      coAppZipCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      coAppState: Yup.string()
        .required('Please select a state.')
        .test('isStateAllowed', 'Currently out of trade area.', val => isStateAllowed(val)),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeInfoValue('coAppStreet', values.coAppStreet);
      dispatchChangeInfoValue('coAppCity', values.coAppCity);
      dispatchChangeInfoValue('coAppZipCode', values.coAppZipCode);
      dispatchChangeInfoValue('coAppState', values.coAppState);
      dispatchChangePage(MicrofFlowPageNumber.CoAppIdentificationInfo);
      dispatchSubmitMindfire({
        email: pqi.email,
        lastPage: '4088',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={MicrofFlowPageNumber.CoApplicantDetails} />
        <Col sm="5">
          {children}
          <p>
            Please enter your address as it appears on official documents, such as your property tax
            bill. We’ll use this information to verify property ownership.
          </p>
        </Col>
        <Col sm="6">
          <CoAppAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  microfInformation: state.microfInformation,
  aquaInformation: state.aquaInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppAddress.propTypes = {
  dispatchChangePage: PropTypes.func,
  microfInformation: PropTypes.object,
  dispatchChangeInfoValue: PropTypes.func,
  pqi: PropTypes.object,
  aquaInformation: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppAddress);
