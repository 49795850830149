import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import Input from '../../components/UI/Form/Input/CustomInput';
import CustomPhoneNumber from '../../components/UI/Form/PhoneNumber/PhoneNumber';
import { changePage, changePreQualInfoValue, submitMindfire } from '../../redux/actions/index';
import { reactGAPageview, reactGAEvent } from '../../helper';
import { getIsRheem } from '../../config/settings';
import { AquaPreQualificationFlowPageNumber } from './preQualificationFlow';
import {
  FIRSTNAME_TOOLTIP_TEXT,
  LASTNAME_TOOLTIP_TEXT,
  MIDDLENAME_TOOLTIP_TEXT,
  PHONENUMBER_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';

const isRheem = getIsRheem();

const UserInfo = props => {
  const {
    email,
    firstName,
    middleName,
    lastName,
    phoneNumber,
    dispatchChangePage,
    dispatchChangeValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    microfInformation,
    aquaFinanceAssigned,
    verifyAddressChecked,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_consumerinfo1',
      trackerNames: [],
      title: 'ca_consumerinfo1',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const UserInfoForm = () => (
    <Form>
      <Field
        component={Input}
        label="First Name"
        name="firstName"
        id="firstName"
        tooltipText={FIRSTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label={`Middle Initial${isRheem ? ' (If Applicable – Optional Field)' : ''}`}
        name="middleName"
        id="middleName"
        tooltipText={MIDDLENAME_TOOLTIP_TEXT}
        type="text"
        maxLength="2"
      />
      <Field
        component={Input}
        label="Last Name"
        name="lastName"
        id="lastName"
        tooltipText={LASTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomPhoneNumber}
        label="Phone Number (Mobile Preferred)"
        name="phoneNumber"
        id="phoneNumber"
        tooltipText={PHONENUMBER_TOOLTIP_TEXT}
      />
      <Button type="submit" title="Continue" />
    </Form>
  );

  const UserInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      firstName: firstName || microfInformation.firstName || '',
      middleName: middleName || microfInformation.middleName || '',
      lastName: lastName || microfInformation.lastName || '',
      phoneNumber: phoneNumber || microfInformation.phoneNumber || '',
    }),

    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter your first name.'),
      middleName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .max(2, 'Middle initial is too long.'),
      lastName: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, 'Special characters are not allowed.')
        .required('Please enter your last name.'),
      phoneNumber: Yup.string()
        .test('required', 'Please enter your phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeValue('firstName', values.firstName.trim());
      dispatchChangeValue('middleName', values.middleName.trim());
      dispatchChangeValue('lastName', values.lastName.trim());
      dispatchChangeValue('phoneNumber', values.phoneNumber.match(/\d/g).join(''));
      dispatchChangePage(AquaPreQualificationFlowPageNumber.UserAddress);
      dispatchSubmitMindfire({
        email: email,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        mobilePhone: values.phoneNumber,
        lastPage: '4072',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(UserInfoForm);

  return (
    <Container>
      <Row>
        {!verifyAddressChecked ? (
          <BackBtn
            changePage={dispatchChangePage}
            page={
              isRheem
                ? AquaPreQualificationFlowPageNumber.LoanFor
                : aquaFinanceAssigned
                ? AquaPreQualificationFlowPageNumber.LoanAmount
                : AquaPreQualificationFlowPageNumber.AccountEmail
            }
          />
        ) : (
          <Col sm="1" />
        )}
        <Col sm="5">
          {children}
          <p>
            Please enter your name as it appears on legal documents, such as your property deed.
            We’ll use this information to verify your application information.
          </p>
        </Col>
        <Col sm="6">
          <UserInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  firstName: state.preQualificationInformation.firstName,
  middleName: state.preQualificationInformation.middleName,
  lastName: state.preQualificationInformation.lastName,
  phoneNumber: state.preQualificationInformation.phoneNumber,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  microfInformation: state.microfInformation,
  verifyAddressChecked: state.preQualificationInformation.verifyAddressChecked,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

UserInfo.propTypes = {
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserInfo);
