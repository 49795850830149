import axios from 'axios';
import { baseURL, handleApiError } from './api';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { CHANGE_UTILE_VALUE } from './actionTypes';
import { getCurrentENV, getSmartyKey } from '../../config/settings';
import { changePreQualInfoValue } from './preQualInfoActions';
import { changePage } from './naviActions';

export const changeUtileValue = (key, value) => ({
  type: CHANGE_UTILE_VALUE,
  key,
  value,
});

export const getDistributors = () => async dispatch => {
  await axios
    .get(`${baseURL}/api/distributors`)
    .then(response => {
      dispatch(changeUtileValue('distributors', response.data));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const getBanks = () => async dispatch => {
  await axios
    .get(`${baseURL}/api/bank/showOnDealerEnrollment`)
    .then(response => {
      dispatch(changeUtileValue('banks', response.data));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const getHCBrands = applicationId => async dispatch => {
  await axios
    .get(`${baseURL}/api/applications/hcBrands`)
    .then(response => {
      dispatch(changeUtileValue('hcBrands', response.data));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const validateAddress = (address, nextPage) => async dispatch => {
  dispatch(startLoading('Validating Address...'));
  dispatch(changePreQualInfoValue('street', address.street));
  dispatch(changePreQualInfoValue('city', address.city));
  dispatch(changePreQualInfoValue('zipcode', address.zipcode));
  dispatch(changePreQualInfoValue('state', address.state));
  if (
    getCurrentENV() !== 'PROD' &&
    address?.city?.toUpperCase() === process.env.REACT_APP_BYPASS_VALUE
  ) {
    if (nextPage) {
      dispatch(changePage(nextPage));
    }
  } else {
    await axios
      .get(
        `https://us-street.api.smarty.com/street-address?key=${getSmartyKey()}&street=${
          address.street
        }&street2=&city=${address.city}&state=${address.state}&zipcode=${
          address.zipcode
        }&candidates=10&match=strict&license=us-core-cloud`,
      )
      .then(response => {
        if (response.data) {
          const validAddress = response.data.length > 0 ? response.data : [];

          if (
            validAddress.length > 0 &&
            (validAddress[0].delivery_line_1 !== address.street ||
              validAddress[0].components.city_name !== address.city ||
              validAddress[0].components.state_abbreviation !== address.state ||
              validAddress[0].components.zipcode !== address.zipcode)
          ) {
            dispatch(changePreQualInfoValue('validAddress', validAddress));
            dispatch(changePreQualInfoValue('checkAddress', true));
          } else {
            if (validAddress.length === 0) {
              dispatch(changePreQualInfoValue('validAddress', validAddress));
              dispatch(changePreQualInfoValue('checkAddress', true));
            } else {
              if (nextPage) {
                dispatch(changePage(nextPage));
              }
            }
          }
        } else {
          dispatch(
            addNotification({
              message: 'This address is invalid!',
              messageType: 'Error',
            }),
          );
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
      });
  }
  dispatch(endLoading());
};
