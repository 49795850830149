import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { addNotification, changePage, changePreQualInfoValue } from '../../../redux/actions';
import { isStateAllowed } from '../../../helper';

const CheckAddressModal = props => {
  const {
    isOpen,
    className,
    nextPage,
    pqi,
    dispatchChangeValue,
    dispatchChangePage,
    dispatchNotification,
  } = props;

  const address = pqi.validAddress?.length > 0 ? pqi.validAddress[0] : null;

  const toggleModal = () => {
    dispatchChangeValue('checkAddress', false);
  };

  const confirmAddress = () => {
    dispatchChangeValue('checkAddress', false);
    dispatchChangeValue('street', address.delivery_line_1);
    dispatchChangeValue('city', address.components.city_name);
    dispatchChangeValue('zipcode', address.components.zipcode);
    dispatchChangeValue('state', address.components.state_abbreviation);
    if (isStateAllowed(address.components.state_abbreviation)) {
      dispatchChangePage(nextPage);
    } else {
      dispatchNotification({ message: 'Currently out of trade area.', messageType: 'Error' });
    }
  };

  const continueAsEntered = () => {
    dispatchChangeValue('checkAddress', false);
    dispatchChangePage(nextPage);
  };

  return (
    <Modal isOpen={isOpen} className={className}>
      <ModalHeader toggle={toggleModal}>
        <b>{address ? 'Is this the correct address?' : 'Invalid address!'}</b>
      </ModalHeader>
      {address ? (
        <>
          <ModalBody>
            <>
              <div className="">
                <span>{address?.delivery_line_1}</span>{' '}
                <span>{`${address?.components.city_name} ${
                  address?.components.state_abbreviation
                } ${address?.components.zipcode}`}</span>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={confirmAddress}>
              Yes
            </Button>
            <Button color="secondary" outline onClick={toggleModal}>
              No
            </Button>
            <Button color="primary" onClick={continueAsEntered}>
              Continue as entered
            </Button>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalBody>
            <>
              <div className="">
                <span>Continue and use address as entered?</span>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={continueAsEntered}>
              Yes
            </Button>
            <Button color="secondary" outline onClick={toggleModal}>
              No
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

const mapStateToProps = state => ({ pqi: state.preQualificationInformation });

const mapDispatchToProps = dispatch => ({
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchNotification: (messageType, message) => dispatch(addNotification(messageType, message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckAddressModal);
