import { AgeFromDateString } from 'age-calculator';
import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import DatePicker from '../../../components/UI/Form/DatePicker/DatePicker';
import SsnFormat from '../../../components/UI/Form/NumberFormat/SsnFormat';
import { getIsOptimus } from '../../../config/settings';
import { COAPPDOB_TOOLTIP_TEXT, COAPPFULLSSN_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview, validDateFormat } from '../../../helper';
import { changeMicrofInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import { MicrofFlowPageNumber } from '../MicrofFlow';

const CoAppDetails = props => {
  const {
    microfInformation,
    dispatchChangeInfoValue,
    children,
    dispatchChangePage,
    aquaInformation,
    appFullSsn,
    dispatchSubmitMindfire,
    email,
    did,
    dealerUserId,
  } = props;

  const isOptimus = getIsOptimus();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_coappssn',
      trackerNames: [],
      title: 'ca_coappssn',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const CoAppDetailsForm = () => {
    return (
      <Form noValidate autoComplete="off">
        <Field
          component={DatePicker}
          name="coAppDoB"
          id="coAppDoB"
          tooltipText={COAPPDOB_TOOLTIP_TEXT}
          label="Co-Applicant's Date of Birth"
        />
        <Field
          component={SsnFormat}
          label={'Co-Applicant’s full Social Security Number'}
          name="coAppFullSsn"
          id="coAppFullSsn"
          tooltipText={COAPPFULLSSN_TOOLTIP_TEXT}
          type="password"
          icon="lock"
        />

        <Button type="submit" title="Continue" />
      </Form>
    );
  };

  const CoAppDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppDoB: microfInformation.coAppDoB || aquaInformation.coAppDoB || '',
      coAppFullSsn: microfInformation.coAppFullSsn || aquaInformation.coAppFullSsn || '',
    }),

    validationSchema: Yup.object({
      coAppDoB: Yup.string()
        .required("Please enter the Co-Applicant's date of birth.")
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          'The Co-Applicant must be at least 18 years old.',
          val => val && new AgeFromDateString(val).age >= 18,
        ),
      coAppFullSsn: Yup.string()
        .required("Please enter the Co-Applicant's full SSN.")
        .typeError('Only numbers allowed.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'unique',
          "Same SSN as the applicant's is not allowed.",
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('') !== appFullSsn,
        ),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeInfoValue('coAppDoB', values.coAppDoB);
      dispatchChangeInfoValue(
        'coAppFullSsn',
        values.coAppFullSsn
          .toString()
          .match(/\d/g)
          .join(''),
      );
      dispatchChangePage(MicrofFlowPageNumber.CoApplicantAddress);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '4092',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={MicrofFlowPageNumber.CoApplicantInfo} />
        <Col sm="5">
          {children}
          <p>We’ll need this information to verify your identity.</p>
        </Col>
        <Col sm="6">
          <CoAppDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  microfInformation: state.microfInformation,
  aquaInformation: state.aquaInformation,
  appFullSsn: state.microfInformation.appFullSSN,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppDetails.propTypes = {
  dispatchChangePage: PropTypes.func,
  microfInformation: PropTypes.object,
  dispatchChangeInfoValue: PropTypes.func,
  aquaInformation: PropTypes.object,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CoAppDetails),
);
