import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import { changePage, submitMindfire, changeTheme } from '../../redux/actions';
import { MicrofFlowPageNumber } from './MicrofFlow';
import { reactGAEvent } from '../../helper';
import ThemeEnum from '../../assets/js/ThemeEnum';
import { getIsRheem } from '../../config/settings';

const isRheem = getIsRheem();

const Landing = props => {
  const {
    dispatchSubmitMindfire,
    dispatchChangePage,
    did,
    dealerUserId,
    email,
    dispatchChangeTheme,
    score,
  } = props;

  useEffect(() => {
    if (isRheem) {
      dispatchChangeTheme(ThemeEnum.MICROF.value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <p className="fs-32">
          <b className="primary">You've prequalified for a Microf Lease Offer</b>
        </p>
      </Row>
      <Row className="justify-content-center ">
        <Col xs="auto" lg="8" sm="12" md="12">
          <p className="fs-22 fw-500 pl-40">
            Microf’s lease purchase option is different than financing:
          </p>
          <ul className="powerpay-microf-lease-purchase-options-arrow">
            <li>
              Pay as you go!{' '}
              <span className="fw-normal">
                Monthly renewals up to 84 months to own in most states.
              </span>
            </li>
            <li>
              Own the item(s) after paying the total of payments to ownership.{' '}
              <span className="fw-normal">
                Substantial savings are available through Early Purchase Options!
              </span>
            </li>
            <li>
              Damaged credit?{' '}
              <span className="fw-normal">
                Microf regularly approves applicants with less than perfect credit.
              </span>
            </li>
            <li>
              Have questions?{' '}
              <span className="fw-normal">
                Call us at 855-642-7631 or email us at info@microf.com.
              </span>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col xs="auto" lg="8" sm="12" md="12">
          <p className="fs-22 fw-500 pl-40">
            Here is what you will need to complete the application:
          </p>
          <ul className="microf-lease-purchase-options">
            <li>Social Security Number</li>
            <li>Bank account information (routing and account numbers)</li>
            <li>Income or benefits information (e.g., paystub, bank statement, etc.)</li>
            <li>Co-applicant information (not required)</li>
          </ul>
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col xs="auto" lg="8" sm="12" md="12">
          <p className="fs-22 fw-500 pl-40">Not eligible for lease purchase:</p>
          <ul className="powerpay-microf-lease-purchase-options-x">
            <li>Homes on rented land (e.g., mobile homes in a park)</li>
            <li>Reverse mortgages</li>
            <li>Rent-to-own or lease-to-own properties</li>
            <li>Homeowners with active or dismissed bankruptcies</li>
          </ul>
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Col xs="12" md="auto">
          <Button
            color="primary"
            onClick={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Start Application',
                did,
                dealerUserId,
              });
              dispatchChangePage(MicrofFlowPageNumber.AccountEmail);
              dispatchSubmitMindfire({
                email: email,
                lastPage: '4066',
                dealerUserId: dealerUserId,
                did: did,
              });
            }}
          >
            Continue to Microf
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  page: state.navi.microfFlowCurrentPage,
  email: state?.preQualificationInformation?.email || state?.softPull?.softPullResponse?.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  score: state.softPull.softPullResponse?.score,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchChangeTheme: theme => dispatch(changeTheme(theme)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Landing);
