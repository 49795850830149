import React, { useState } from 'react';
import { Col, Row, Button, Input, Form } from 'reactstrap';
import NumberFormat from 'react-number-format';
import './Application.scss';
import {
  allApplicationStatusEnum,
  getApplicationStatus,
  isStatusAquaFinance,
  isStatusAquaDecline,
  isMosaicDeclined,
  isPowerPayDeclined,
} from '../../../assets/js/ApplicationStatusEnum';
import ApplicationCategoryEnum from '../../../assets/js/ApplicationCategoryEnum';
import { formatDate } from '../../../util/dateFormatter';
import selectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import AddedByAPIEnum from '../../../assets/js/AddedByAPIEnum';
import ThemeEnum from '../../../assets/js/ThemeEnum';
import { getIsMicrof, getIsOptimus, getIsPool, getIsRheem } from '../../../config/settings';
import { connect } from 'react-redux';
import {
  changeSoftPullValue,
  changeTheme,
  movePrequalificationToHistory,
  updateExternalId,
} from '../../../redux/actions';
import ExternalReferenceIdModal from '../Modal/ExternalReferenceIdModal.js';
import userRoleEnum from '../../../assets/js/UserRoleEnum';
import { isMobile } from 'react-device-detect';
import { isAddedFromFinturf, showItemEmulationMode } from '../../../helper';
import AddedFromEnum from '../../../assets/js/AddedFromEnum';

const isRheem = getIsRheem();
const isOptimus = getIsOptimus();
const isMicrof = getIsMicrof();

const Application = ({
  value,
  isEmulated,
  path,
  history,
  dispatchChangeTheme,
  dispatchMovePrequalificationToHistory,
  dispatchChangeSoftPullValue,
  dispatchUpdateExternalId,
  auth,
  workUser,
  user,
}) => {
  let appStatus = getApplicationStatus(value.status);
  const [revisitApp, setRevisitApp] = useState(false);
  const [selectAquaFinanceProduct, setSelectAquaFinanceProduct] = useState(false);
  const [aquaFinance, setAquaFinance] = useState(false);
  const [showExternalReferenceIdModal, setShowExternalReferenceIdModal] = useState({
    show: false,
    data: null,
  });

  const isMaintenance =
    user?.dealerStatsSummaryDTO?.siteMessages &&
    user?.dealerStatsSummaryDTO?.siteMessages.length > 0 &&
    user?.dealerStatsSummaryDTO?.siteMessages.find(x => x.message.includes('under maintenance'))
      ? true
      : false;

  const toggleExternalReferenceIdModal = data => {
    setShowExternalReferenceIdModal({ show: !showExternalReferenceIdModal.show, data: data });
  };

  const submitInfo = data => {
    dispatchUpdateExternalId(data.preqId, data.externalId);
    setShowExternalReferenceIdModal({
      show: false,
      data: null,
    });
  };

  const openDetails = () => {
    if (
      value.selectedFinancier === selectedFinancierEnum.GREENSKY.value ||
      value.selectedFinancier === selectedFinancierEnum.FTL.value
    ) {
      dispatchChangeSoftPullValue('application', null);
      dispatchChangeSoftPullValue('greenSkyApplication', null);
      history.push(path + '?appId=' + value.id);
    }
  };

  const isFundingCompleted = () => {
    return !!(value.firstPayment && value.agreementSigned);
  };

  const completeFundMicrof = () => {
    if (value.selectedFinancier === selectedFinancierEnum.RHEEM.value) {
      history.push('/portal/open-applications/complete-fund-rheem-application?appId=' + value.id);
    } else {
      history.push('/portal/open-applications/complete-fund-microf-application?appId=' + value.id);
      if (isRheem) {
        dispatchChangeTheme(ThemeEnum.MICROF.value);
      }
    }
  };

  const revisitPrequalification = () => {
    history.push(`/?pqiToken=${value.token}&pqiId=${value.id}`);
  };

  const movePreqToHistory = pqId => {
    dispatchMovePrequalificationToHistory(pqId, auth);
  };

  const cancel = () => {
    history.push('/portal/dashboard');
  };

  const renderAdditionalInfo = () => {
    if (isFundingCompleted()) {
      return (
        <Col xs="12" className="bold green no-padding">
          Ready to install.
        </Col>
      );
    }
    return (
      <Col xs="12" className="bold red no-padding">
        Do not install until customer has made 1st payment and signed lease.
      </Col>
    );
  };

  return value && value.moveToHistory !== true ? (
    <div className="app-panel">
      <div className="panel-body">
        <Row onClick={() => openDetails()}>
          <Col sm="6" xs="12">
            <div>{value.nameFirst + ' ' + value.nameLast}</div>
            {value.coapplicantNameFirst ? (
              <div>{value.coapplicantNameFirst + ' ' + value.coapplicantNameLast}</div>
            ) : null}
            <div>{value.street}</div>
            <div>{value.city + ', ' + value.state + ' ' + value.zipcode}</div>
            {value.phone ? (
              value.phone.length > 6 ? (
                <div>
                  {'(' +
                    value.phone.substring(0, 3) +
                    ') ' +
                    value.phone.substring(3, 6) +
                    '-' +
                    value.phone.substring(6)}
                </div>
              ) : (
                <div>{value.phone}</div>
              )
            ) : null}
            <div className="text-break">{value.email}</div>
          </Col>
          <Col sm="6" xs="12">
            <Row>
              {value.rtoNumber ? (
                <Col xs="12" className="no-padding">
                  {value.rtoNumber}
                </Col>
              ) : null}
              {value.totalPrice && (
                <>
                  <Col xs="6" className="bold no-padding">
                    Total Job Price:
                  </Col>
                  <Col xs="6">
                    <NumberFormat
                      value={value.totalPrice}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                </>
              )}

              {value.totalFinancing && (
                <>
                  <Col xs="6" className="bold no-padding">
                    Total Loan or Lease Amount:
                  </Col>
                  <Col xs="6">
                    <NumberFormat
                      value={value.totalFinancing}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                </>
              )}
              {value.contractorDiscount ? (
                <>
                  <Col xs="6" className="bold no-padding">
                    Contractor Discount:
                  </Col>
                  <Col xs="6">{value.contractorDiscount + '%'}</Col>
                </>
              ) : null}
              {value.selectedFinancier && (
                <>
                  <Col xs="6" className="bold no-padding">
                    Selected financier:
                  </Col>
                  <Col xs="6">{value.selectedFinancier}</Col>
                  {appStatus &&
                    ApplicationCategoryEnum.OPEN === appStatus.category &&
                    !value.contractorInitials &&
                    (value.selectedFinancier === selectedFinancierEnum.MICROF.value ||
                      value.selectedFinancier === selectedFinancierEnum.RHEEM.value) &&
                    renderAdditionalInfo()}
                </>
              )}

              {value.systemType && (
                <>
                  <Col xs="6" className="bold no-padding">
                    Lease Project Type:
                  </Col>
                  <Col xs="6">{value.systemType}</Col>
                </>
              )}
              {value.selectedFinancier === selectedFinancierEnum.MICROF.value &&
              value.addedBy === null ? (
                auth.user.user && auth.user.user.emulatorId ? (
                  workUser?.addedFrom === AddedFromEnum.MICROF.value ? (
                    <>
                      <Col xs="6" className={`bold ${isMobile ? '' : 'no-padding'}`}>
                        External Reference ID:
                      </Col>
                      <Col xs="6" className="text-break">
                        {value.externalReferenceId}{' '}
                        {workUser?.role === userRoleEnum.DEALER_ADMIN.value ||
                        workUser?.role === userRoleEnum.DEALER_RELATIONSHIP_REP.value ? (
                          <Button
                            color="primary"
                            id="setExternalReferenceId"
                            className="rounded-pill w-50px p-0 fs-14"
                            onClick={() => {
                              toggleExternalReferenceIdModal({
                                externalReferenceId: value.externalReferenceId,
                                preqId: value.preqId,
                              });
                            }}
                          >
                            {value.externalReferenceId ? `Edit` : `Add`}
                          </Button>
                        ) : null}
                        <ExternalReferenceIdModal
                          isOpen={showExternalReferenceIdModal.show}
                          toggleModal={toggleExternalReferenceIdModal}
                          data={showExternalReferenceIdModal.data}
                          onSendClick={submitInfo}
                        />
                      </Col>
                    </>
                  ) : null
                ) : (
                  <>
                    <Col xs="6" className={`bold ${isMobile ? '' : 'no-padding'}`}>
                      External Reference ID:
                    </Col>
                    <Col xs="6" className="text-break">
                      {value.externalReferenceId}{' '}
                      {workUser?.role === userRoleEnum.DEALER_ADMIN.value ||
                      workUser?.role === userRoleEnum.DEALER_RELATIONSHIP_REP.value ? (
                        <Button
                          color="primary"
                          id="setExternalReferenceId"
                          className="rounded-pill w-50px p-0 fs-14"
                          onClick={() => {
                            toggleExternalReferenceIdModal({
                              externalReferenceId: value.externalReferenceId,
                              preqId: value.preqId,
                            });
                          }}
                        >
                          {value.externalReferenceId ? `Edit` : `Add`}
                        </Button>
                      ) : null}
                      <ExternalReferenceIdModal
                        isOpen={showExternalReferenceIdModal.show}
                        toggleModal={toggleExternalReferenceIdModal}
                        data={showExternalReferenceIdModal.data}
                        onSendClick={submitInfo}
                      />
                    </Col>
                  </>
                )
              ) : null}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col xs="6" sm="3" className="bold">
                Status:
              </Col>
              <Col xs="6" sm="9">
                {appStatus ? appStatus.description || appStatus.name : null}
              </Col>
            </Row>
            {value.applicationStatus && (
              <Row>
                <Col xs="6" sm="3" className="bold">
                  Application Status:
                </Col>
                <Col xs="6" sm="9">
                  {value.applicationStatus}
                </Col>
              </Row>
            )}
            <Row>
              <Col xs="6" sm="3" className="bold">
                Submitted:
              </Col>
              <Col xs="6" sm="9" className="text-break">
                {formatDate(value.submitted)}
              </Col>
            </Row>
            {appStatus && ApplicationCategoryEnum.OPEN === appStatus.category ? (
              <>
                <Row>
                  <Col xs="6" sm="3" className="bold">
                    First Payment:
                  </Col>
                  <Col xs="6" sm="9">
                    {value.firstPayment ? formatDate(value.firstPayment) : '-'}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" sm="3" className="bold">
                    Is Agreement Signed:
                  </Col>
                  <Col xs="6" sm="9">
                    {value.agreementSigned ? 'Yes' : 'No'}
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </Row>

        {appStatus &&
        value.selectedFinancier === selectedFinancierEnum.AQUA_FINANCE.value &&
        isStatusAquaFinance(appStatus.value) ? (
          <Row>
            {auth.user.user && auth.user.user.emulatorId ? (
              showItemEmulationMode(auth?.user?.user) ? (
                <Col className="text-right">
                  {isStatusAquaDecline(appStatus.value) ? (
                    <Form
                      title={'formAquaFinance' + value.id}
                      method="get"
                      action="/"
                      className="inline"
                      onSubmit={() => setRevisitApp(true)}
                    >
                      <Input name="appId" hidden value={value.id || ''} readOnly={true} />
                      <Input
                        name="appSelectedFinancier"
                        hidden
                        value={value.selectedFinancier || ''}
                        readOnly={true}
                      />
                      <Input
                        name="aquaAppNo"
                        hidden
                        value={value.aquaApplicationNumber || ''}
                        readOnly={true}
                      />
                      <Button
                        type="submit"
                        color="primary"
                        className="rounded-pill"
                        disabled={revisitApp}
                      >
                        {revisitApp ? 'Revisit ...' : 'Revisit'}
                      </Button>
                    </Form>
                  ) : !isEmulated && value.shouldSelectAquaProduct ? (
                    <Form
                      title={'formAquaFinance' + value.id}
                      method="get"
                      action="/"
                      className="inline"
                      onSubmit={() => setSelectAquaFinanceProduct(true)}
                    >
                      <Input
                        name="aquaAppNo"
                        hidden
                        value={value.aquaApplicationNumber || ''}
                        readOnly={true}
                      />
                      <Input
                        name="appSelectedFinancier"
                        hidden
                        value={value.selectedFinancier || ''}
                        readOnly={true}
                      />
                      <Input name="appId" hidden value={value.id || ''} readOnly={true} />
                      <Button
                        type="submit"
                        color="primary"
                        className="rounded-pill"
                        disabled={selectAquaFinanceProduct}
                      >
                        {selectAquaFinanceProduct
                          ? 'Select Aqua Finance Product ...'
                          : 'Select Aqua Finance Product'}
                      </Button>
                    </Form>
                  ) : (
                    <Form
                      target="_blank"
                      title={'formAquaFinance' + value.id}
                      method="post"
                      action={value.consumerUrl}
                      className="inline"
                      onSubmit={() => setAquaFinance(true)}
                    >
                      <Input
                        hidden
                        name="SAMLResponse"
                        value={value.samlResponse}
                        readOnly={true}
                      />
                      <Input
                        hidden
                        name="RelayState"
                        value={value.orbitUrl + value.aquaApplicationNumber + '/detail'}
                        readOnly={true}
                      />
                      <Button
                        type="submit"
                        color="primary"
                        className="rounded-pill"
                        disabled={aquaFinance}
                      >
                        {aquaFinance ? 'Aqua Finance ...' : 'Aqua Finance'}
                      </Button>
                    </Form>
                  )}
                </Col>
              ) : null
            ) : (
              <Col className="text-right">
                {isStatusAquaDecline(appStatus.value) ? (
                  <Form
                    title={'formAquaFinance' + value.id}
                    method="get"
                    action="/"
                    className="inline"
                    onSubmit={() => setRevisitApp(true)}
                  >
                    <Input name="appId" hidden value={value.id || ''} readOnly={true} />
                    <Input
                      name="appSelectedFinancier"
                      hidden
                      value={value.selectedFinancier || ''}
                      readOnly={true}
                    />
                    <Input
                      name="aquaAppNo"
                      hidden
                      value={value.aquaApplicationNumber || ''}
                      readOnly={true}
                    />
                    <Button
                      type="submit"
                      color="primary"
                      className="rounded-pill"
                      disabled={revisitApp}
                    >
                      {revisitApp ? 'Revisit ...' : 'Revisit'}
                    </Button>
                  </Form>
                ) : !isEmulated && value.shouldSelectAquaProduct ? (
                  <Form
                    title={'formAquaFinance' + value.id}
                    method="get"
                    action="/"
                    className="inline"
                    onSubmit={() => setSelectAquaFinanceProduct(true)}
                  >
                    <Input
                      name="aquaAppNo"
                      hidden
                      value={value.aquaApplicationNumber || ''}
                      readOnly={true}
                    />
                    <Input
                      name="appSelectedFinancier"
                      hidden
                      value={value.selectedFinancier || ''}
                      readOnly={true}
                    />
                    <Input name="appId" hidden value={value.id || ''} readOnly={true} />
                    <Button
                      type="submit"
                      color="primary"
                      className="rounded-pill"
                      disabled={selectAquaFinanceProduct}
                    >
                      {selectAquaFinanceProduct
                        ? 'Select Aqua Finance Product ...'
                        : 'Select Aqua Finance Product'}
                    </Button>
                  </Form>
                ) : (
                  <Form
                    target="_blank"
                    title={'formAquaFinance' + value.id}
                    method="post"
                    action={value.consumerUrl}
                    className="inline"
                    onSubmit={() => setAquaFinance(true)}
                  >
                    <Input hidden name="SAMLResponse" value={value.samlResponse} readOnly={true} />
                    <Input
                      hidden
                      name="RelayState"
                      value={value.orbitUrl + value.aquaApplicationNumber + '/detail'}
                      readOnly={true}
                    />
                    <Button
                      type="submit"
                      color="primary"
                      className="rounded-pill"
                      disabled={aquaFinance}
                    >
                      {aquaFinance ? 'Aqua Finance ...' : 'Aqua Finance'}
                    </Button>
                  </Form>
                )}
              </Col>
            )}
          </Row>
        ) : appStatus &&
          allApplicationStatusEnum.STARTED === appStatus &&
          value.selectedFinancier === selectedFinancierEnum.AQUA_FINANCE.value ? (
          <Row>
            {auth.user.user && auth.user.user.emulatorId ? (
              showItemEmulationMode(auth?.user?.user) ? (
                <Col className="text-right">
                  <Form
                    title={'formAquaFinance' + value.id}
                    method="get"
                    action="/"
                    className="inline"
                    onSubmit={() => setRevisitApp(true)}
                  >
                    <Input name="appId" hidden value={value.id || ''} readOnly={true} />
                    <Input
                      name="appSelectedFinancier"
                      hidden
                      value={value.selectedFinancier || ''}
                      readOnly={true}
                    />
                    <Button
                      type="submit"
                      color="primary"
                      className="rounded-pill"
                      disabled={revisitApp}
                    >
                      {revisitApp ? 'Revisit ...' : 'Revisit'}
                    </Button>
                  </Form>
                </Col>
              ) : null
            ) : (
              <Col className="text-right">
                <Form
                  title={'formAquaFinance' + value.id}
                  method="get"
                  action="/"
                  className="inline"
                  onSubmit={() => setRevisitApp(true)}
                >
                  <Input name="appId" hidden value={value.id || ''} readOnly={true} />
                  <Input
                    name="appSelectedFinancier"
                    hidden
                    value={value.selectedFinancier || ''}
                    readOnly={true}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    className="rounded-pill"
                    disabled={revisitApp}
                  >
                    {revisitApp ? 'Revisit ...' : 'Revisit'}
                  </Button>
                </Form>
              </Col>
            )}
          </Row>
        ) : null}
        {value.consumerApplication ? (
          appStatus &&
          !(
            value.selectedFinancier === selectedFinancierEnum.AQUA_FINANCE.value ||
            (value.selectedFinancier === selectedFinancierEnum.MICROF.value &&
              isStatusAquaDecline(appStatus.value))
          ) ? (
            <Row className="mt-3 justify-content-end">
              {auth.user.user && auth.user.user.emulatorId ? (
                showItemEmulationMode(auth?.user?.user) ? (
                  <Col sm="12" xs="12" md="auto">
                    <Form
                      target="_blank"
                      title="formRevisitMicrofApp"
                      method="get"
                      action="/"
                      className="inline"
                      onSubmit={() => setRevisitApp(true)}
                    >
                      <Input name="appId" hidden value={value.id || ''} readOnly={true} />
                      <Input name="appToken" hidden value={value.token || ''} readOnly={true} />
                      <Button
                        type="submit"
                        color="primary"
                        className="rounded-pill w-100 mt-1"
                        disabled={revisitApp}
                      >
                        {revisitApp ? 'Complete Application ...' : 'Complete Application'}
                      </Button>
                    </Form>
                  </Col>
                ) : null
              ) : (
                <Col sm="12" xs="12" md="auto">
                  <Form
                    target="_blank"
                    title="formRevisitMicrofApp"
                    method="get"
                    action="/"
                    className="inline"
                    onSubmit={() => setRevisitApp(true)}
                  >
                    <Input name="appId" hidden value={value.id || ''} readOnly={true} />
                    <Input name="appToken" hidden value={value.token || ''} readOnly={true} />
                    <Button
                      type="submit"
                      color="primary"
                      className="rounded-pill w-100 mt-1"
                      disabled={revisitApp}
                    >
                      {revisitApp ? 'Complete Application ...' : 'Complete Application'}
                    </Button>
                  </Form>
                </Col>
              )}
            </Row>
          ) : null
        ) : value.selectedFinancier !== selectedFinancierEnum.GREENSKY.value &&
          value.selectedFinancier !== selectedFinancierEnum.FTL.value &&
          appStatus &&
          ApplicationCategoryEnum.OPEN !== appStatus.category &&
          (value.selectedFinancier !== selectedFinancierEnum.AQUA_FINANCE.value ||
            isMosaicDeclined(appStatus.value) ||
            isPowerPayDeclined(appStatus.value)) ? (
          <Row className="mt-3 justify-content-end">
            {auth.user.user && auth.user.user.emulatorId ? (
              showItemEmulationMode(auth?.user?.user) ? (
                <Col sm="12" xs="12" md="auto">
                  <Form
                    title="formRevisitMicrofApp"
                    method="get"
                    action="/"
                    className="inline"
                    onSubmit={() => setRevisitApp(true)}
                  >
                    <Input name="appId" hidden value={value.id || ''} readOnly={true} />
                    <Input
                      name="appSelectedFinancier"
                      hidden
                      value={value.selectedFinancier || ''}
                      readOnly={true}
                    />
                    <Button
                      type="submit"
                      color="primary"
                      className="rounded-pill w-100 mt-1"
                      disabled={revisitApp || isMaintenance}
                    >
                      {revisitApp ? 'Revisit Application ...' : 'Revisit Application'}
                    </Button>
                  </Form>
                </Col>
              ) : null
            ) : (
              <Col sm="12" xs="12" md="auto">
                <Form
                  title="formRevisitMicrofApp"
                  method="get"
                  action="/"
                  className="inline"
                  onSubmit={() => setRevisitApp(true)}
                >
                  <Input name="appId" hidden value={value.id || ''} readOnly={true} />
                  <Input
                    name="appSelectedFinancier"
                    hidden
                    value={value.selectedFinancier || ''}
                    readOnly={true}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    className="rounded-pill w-100 mt-1"
                    disabled={revisitApp || isMaintenance}
                  >
                    {revisitApp ? 'Revisit Application ...' : 'Revisit Application'}
                  </Button>
                </Form>
              </Col>
            )}
          </Row>
        ) : null}

        {appStatus &&
          ApplicationCategoryEnum.OPEN === appStatus.category &&
          value.addedBy !== AddedByAPIEnum.SERVICEFINANCE.name &&
          (value.selectedFinancier === selectedFinancierEnum.MICROF.value ||
            value.selectedFinancier === selectedFinancierEnum.RHEEM.value) &&
          value.contractorInitials == null && (
            <Row className="mt-3 justify-content-end">
              <Col sm="12" xs="12" md="auto">
                <Button
                  type="submit"
                  color="secondary"
                  className="rounded-pill w-100 mt-1"
                  outline
                  onClick={() => {
                    cancel();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              {auth.user.user && auth.user.user.emulatorId ? (
                showItemEmulationMode(auth?.user?.user) ? (
                  <Col sm="12" xs="12" md="auto">
                    <Button
                      type="submit"
                      color="primary"
                      id="completeFundMicrofBtn"
                      className="rounded-pill w-100 mt-1"
                      disabled={isMaintenance || isAddedFromFinturf(workUser)}
                      onClick={() => {
                        completeFundMicrof();
                      }}
                    >
                      Complete / Fund
                    </Button>
                  </Col>
                ) : null
              ) : (
                <Col sm="12" xs="12" md="auto">
                  <Button
                    type="submit"
                    color="primary"
                    id="completeFundMicrofBtn"
                    className="rounded-pill w-100 mt-1"
                    disabled={isMaintenance || isAddedFromFinturf(workUser)}
                    onClick={() => {
                      completeFundMicrof();
                    }}
                  >
                    Complete / Fund
                  </Button>
                </Col>
              )}
            </Row>
          )}

        {appStatus &&
        ApplicationCategoryEnum.OPEN === appStatus.category &&
        value.addedBy === AddedByAPIEnum.SERVICEFINANCE.name &&
        (value.selectedFinancier === selectedFinancierEnum.MICROF.value ||
          value.selectedFinancier === selectedFinancierEnum.RHEEM.value) &&
        value.contractorInitials == null ? (
          <Row className="mt-3 justify-content-end">
            {auth.user.user && auth.user.user.emulatorId ? (
              showItemEmulationMode(auth?.user?.user) ? (
                <Col sm="12" xs="12" md="auto">
                  <Button
                    type="submit"
                    color="primary"
                    id="goToSFPortal"
                    className="rounded-pill w-100 mt-1"
                    onClick={() => {
                      window.open('https://www.svcfin.com/', '_blank');
                    }}
                  >
                    Go to ServiceFinance portal
                  </Button>
                </Col>
              ) : null
            ) : (
              <Col sm="12" xs="12" md="auto">
                <Button
                  type="submit"
                  color="primary"
                  id="goToSFPortal"
                  className="rounded-pill w-100 mt-1"
                  onClick={() => {
                    window.open('https://www.svcfin.com/', '_blank');
                  }}
                >
                  Go to ServiceFinance portal
                </Button>
              </Col>
            )}
          </Row>
        ) : null}

        {value.applicationStatus === 'Prequalification' && (
          <Row className="mt-3 justify-content-end">
            {auth.user.user && auth.user.user.emulatorId ? (
              showItemEmulationMode(auth?.user?.user) ? (
                <Col sm="12" xs="12" md="auto">
                  <Button
                    type="submit"
                    color="primary"
                    id="movePreqalificationToHistory"
                    className="rounded-pill w-100 mt-1"
                    onClick={() => {
                      movePreqToHistory(value.id);
                    }}
                  >
                    Archive Application
                  </Button>
                </Col>
              ) : null
            ) : null}
            <>
              {auth.user.user && auth.user.user.emulatorId ? (
                showItemEmulationMode(auth?.user?.user) ? (
                  <Col sm="12" xs="12" md="auto">
                    <Button
                      type="submit"
                      color="primary"
                      id="revisitPrequal"
                      className="rounded-pill w-100 mt-1"
                      disabled={isMaintenance}
                      onClick={() => {
                        revisitPrequalification();
                      }}
                    >
                      Revisit Prequalification
                    </Button>
                  </Col>
                ) : null
              ) : (
                <Col sm="12" xs="12" md="auto">
                  <Button
                    type="submit"
                    color="primary"
                    id="revisitPrequal"
                    className="rounded-pill w-100 mt-1"
                    disabled={isMaintenance}
                    onClick={() => {
                      revisitPrequalification();
                    }}
                  >
                    Revisit Prequalification
                  </Button>
                </Col>
              )}
            </>
          </Row>
        )}
      </div>
      {value.lastUpdated ? (
        <span className="fw-500">Last Application Update: {formatDate(value.lastUpdated)}</span>
      ) : null}
    </div>
  ) : null;
};

const mapStateToProps = state => ({
  auth: state.auth,
  workUser: state.auth.user.dealerUser || state.auth.user.internalUser,
  user: state.auth.user.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeTheme: theme => dispatch(changeTheme(theme)),
  dispatchMovePrequalificationToHistory: (pqId, auth) =>
    dispatch(movePrequalificationToHistory(pqId, auth)),
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
  dispatchUpdateExternalId: (preqId, externalId) => dispatch(updateExternalId(preqId, externalId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Application);
