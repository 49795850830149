import axios from 'axios';
import AddedByAPIEnum from '../../assets/js/AddedByAPIEnum';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';
import { isMicrofAddedByAPI } from '../../assets/js/ApplicationStatusEnum';
import { getHVACSystem, getSimpleHVACSystem } from '../../assets/js/HvacSystemEnum';
import { getHVACTonnage } from '../../assets/js/HvacTonnageEnum';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';
import userRoleEnum from '../../assets/js/UserRoleEnum';
import { getWaterHeaterSystem } from '../../assets/js/WaterHeaterTypeEnum';
import { getIsRheem } from '../../config/settings';
import { getAddedByAPIValue, isSameAddress } from '../../helper';
import { MicrofFlowPageNumber } from '../../pages/Microf/MicrofFlow';
import { AquaPreQualificationFlowPageNumber } from '../../pages/PreQualification/preQualificationFlow';
import { RheemFlowPageNumber } from '../../pages/Rheem/RheemFlow';
import { defaultFlow, microfFlow } from '../initialState';
import {
  CHANGE_MICROF_INFO_VALUE,
  MICROF_FINISHED,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import { changeAuthValue, loginConsumerUserAfterCreation } from './authActions';
import { resetNewAppState, resetState } from './index';
import { changePage, setFlow } from './naviActions';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { changePreQualInfoValue } from './preQualInfoActions';
import { changeSoftPullValue, mapApplicationToPrequalification } from './softPullActions';

const qs = require('qs');
const isRheem = getIsRheem();

export const changeMicrofInfoValue = (key, value) => ({
  type: CHANGE_MICROF_INFO_VALUE,
  key,
  value,
});

export const mapApplicationToMicrof = app => dispatch => {
  if (app) {
    dispatch(mapApplicationToPrequalification(app));

    // Microf
    dispatch(changeMicrofInfoValue('totalPrice', app.totalJobPrice));
    dispatch(changeMicrofInfoValue('requestedLoanAmount', app.totalJobPrice));

    dispatch(changeMicrofInfoValue('airConditioningSystem', app.airConditioningSystem));

    dispatch(changeMicrofInfoValue('systemType', getSimpleHVACSystem(app.hvacSystemType)));
    dispatch(changeMicrofInfoValue('systemTonnage', getHVACTonnage(app.tonnage)));

    dispatch(
      changeMicrofInfoValue('secondSystemType', getSimpleHVACSystem(app.secondHvacSystemType)),
    );
    dispatch(changeMicrofInfoValue('secondSystemTonnage', getHVACTonnage(app.secondSystemTonnage)));

    dispatch(changeMicrofInfoValue('thirdSystemType', getSimpleHVACSystem(app.waterHeaterType)));
    dispatch(
      changeMicrofInfoValue('fourthSystemType', getSimpleHVACSystem(app.secondWaterHeaterType)),
    );

    dispatch(changeMicrofInfoValue('hvacSystem', getSimpleHVACSystem(app.hvacSystemType)));
    dispatch(changeMicrofInfoValue('hvacTonnage', getHVACTonnage(app.tonnage)));

    if (app.secondHvacSystemType) {
      dispatch(changeMicrofInfoValue('hasSecondSystem', true));
      dispatch(
        changeMicrofInfoValue('secondHvacSystem', getSimpleHVACSystem(app.secondHvacSystemType)),
      );
      dispatch(changeMicrofInfoValue('secondHvacTonnage', getHVACTonnage(app.secondSystemTonnage)));
    }

    if (app.waterHeaterType) {
      dispatch(changeMicrofInfoValue('hasSecondSystem', true));
      dispatch(changeMicrofInfoValue('waterHeater', getSimpleHVACSystem(app.waterHeaterType)));
      if (app.secondHvacSystemType) {
        dispatch(
          changeMicrofInfoValue('secondHvacSystem', getSimpleHVACSystem(app.secondHvacSystemType)),
        );
        dispatch(
          changeMicrofInfoValue('secondHvacTonnage', getHVACTonnage(app.secondSystemTonnage)),
        );
        dispatch(changeMicrofInfoValue('hasThirdSystem', true));
        dispatch(changeMicrofInfoValue('waterHeater', getSimpleHVACSystem(app.waterHeaterType)));
      }
    }

    if (app.secondWaterHeaterType) {
      dispatch(changeMicrofInfoValue('hasThirdSystem', true));
      dispatch(
        changeMicrofInfoValue('secondWaterHeater', getSimpleHVACSystem(app.secondWaterHeaterType)),
      );
      if (app.secondHvacSystemType) {
        dispatch(
          changeMicrofInfoValue('secondHvacSystem', getSimpleHVACSystem(app.secondHvacSystemType)),
        );
        dispatch(
          changeMicrofInfoValue('secondHvacTonnage', getHVACTonnage(app.secondSystemTonnage)),
        );
        dispatch(changeMicrofInfoValue('hasFourthSystem', true));
        dispatch(
          changeMicrofInfoValue(
            'secondWaterHeater',
            getSimpleHVACSystem(app.secondWaterHeaterType),
          ),
        );
      }
    }
    // dispatch(changeMicrofInfoValue('hvacSystem', getHVACSystem(app.hvacSystemType)));
    // dispatch(changeMicrofInfoValue('waterHeater', getWaterHeaterSystem(app.waterHeaterType)));

    // dispatch(changeMicrofInfoValue('hvacSystemType', app.systemType));
    // dispatch(changeMicrofInfoValue('hvacHeatType', app.hvacHeatType));
    // dispatch(changeMicrofInfoValue('hvacTonnage', getHVACTonnage(app.tonnage)));

    // dispatch(changeMicrofInfoValue('secondHvacSystem', getHVACSystem(app.secondHvacSystemType)));
    // dispatch(changeMicrofInfoValue('secondHvacTonnage', getHVACTonnage(app.secondSystemTonnage)));

    // dispatch(changeMicrofInfoValue('secondSystemType', app.secondSystemType));
    // dispatch(changeMicrofInfoValue('secondHeatType', app.secondHeatType));

    // dispatch(
    //   changeMicrofInfoValue(
    //     'hasSecondHvac',
    //     app.secondHeatType && app.secondSystemType && app.secondHvacSystemType ? true : false,
    //   ),
    // );

    // dispatch(
    //   changeMicrofInfoValue('hasSecondWaterHeater', app.secondWaterHeaterType ? true : false),
    // );

    // dispatch(
    //   changeMicrofInfoValue('projectType', getWaterHeaterSystem(app.systemType) ? 'W' : 'A'),
    // );

    dispatch(changeMicrofInfoValue('ownOrRent', app.homeType));
    dispatch(changeMicrofInfoValue('homeownerInsurance', app.insurance));
    dispatch(changeMicrofInfoValue('isHomeowner', true));
    dispatch(
      changeMicrofInfoValue(
        'installmentDate',
        app.installmentDate ||
          (app.aquaFinanceInformation && app.aquaFinanceInformation.installmentDate),
      ),
    );
    dispatch(changeMicrofInfoValue('decisionEngineResubmit', app.decisionEngineResubmit));
    if (app.applicantPrimary) {
      const applicant = app.applicantPrimary;
      dispatch(changePreQualInfoValue('firstName', applicant.nameFirst));
      dispatch(changePreQualInfoValue('middleName', applicant.nameMiddle));
      dispatch(changePreQualInfoValue('lastName', applicant.nameLast));
      dispatch(
        changePreQualInfoValue(
          'phoneNumber',
          applicant.mobilePhone ? applicant.mobilePhone.number : null,
        ),
      );
      dispatch(changePreQualInfoValue('birthday', getAddedByAPIValue(applicant.birthDate) || null));
      if (applicant.address && isMicrofAddedByAPI(app.status)) {
        const address = applicant.address;
        dispatch(
          changeMicrofInfoValue('applicantAddress', {
            city: address.city,
            state: address.state,
            street: address.street,
            zip: address.zipcode,
          }),
        );
      }

      dispatch(changeMicrofInfoValue('appIdType', applicant.idType));
      dispatch(changeMicrofInfoValue('appIdNumber', getAddedByAPIValue(applicant.idNumber)));
      dispatch(changeMicrofInfoValue('appDriversLicenseState', applicant.driversLicenseSOI));
      dispatch(
        changeMicrofInfoValue(
          'appMonthlyNetIncome',
          applicant.monthlyNetIncome || (applicant.grossYearlyIncome * 0.81) / 12,
        ),
      );
      dispatch(
        changeMicrofInfoValue('appBankAccountNumber', getAddedByAPIValue(applicant.bankAccountNum)),
      );
      dispatch(
        changeMicrofInfoValue('appBankAccountRoutingNumber', getAddedByAPIValue(applicant.bankAba)),
      );
      dispatch(changeMicrofInfoValue('appFullSSN', getAddedByAPIValue(applicant.ssn)));
    }
  }
  if (app.applicantNonPrimary) {
    const coapp = app.applicantNonPrimary;
    dispatch(changeMicrofInfoValue('hasCoApp', true));
    dispatch(changeMicrofInfoValue('relationshipWithCoapplicant', coapp.relationshipToPrimary));
    dispatch(changeMicrofInfoValue('coAppFirstName', coapp.nameFirst));
    dispatch(changeMicrofInfoValue('coAppMiddleInitial', coapp.nameMiddle));
    dispatch(changeMicrofInfoValue('coAppLastName', coapp.nameLast));
    dispatch(
      changeMicrofInfoValue(
        'coAppPhoneNumber',
        coapp.mobilePhone ? coapp.mobilePhone.number : null,
      ),
    );
    dispatch(changeMicrofInfoValue('coAppEmail', coapp.email ? coapp.email.email : null));

    dispatch(changeMicrofInfoValue('coAppIdType', coapp.idType));
    dispatch(changeMicrofInfoValue('coAppIdNumber', getAddedByAPIValue(coapp.idNumber)));
    dispatch(changeMicrofInfoValue('coAppDriversLicenseState', coapp.driversLicenseSOI));
    dispatch(
      changeMicrofInfoValue(
        'coAppMonthlyNetIncome',
        coapp.monthlyNetIncome || (coapp.grossYearlyIncome * 0.81) / 12,
      ),
    );
    dispatch(
      changeMicrofInfoValue('coAppBankAccountNumber', getAddedByAPIValue(coapp.bankAccountNum)),
    );
    dispatch(
      changeMicrofInfoValue('coAppBankAccountRoutingNumber', getAddedByAPIValue(coapp.bankAba)),
    );
    dispatch(
      changeMicrofInfoValue('coAppDoB', getAddedByAPIValue(coapp.birthDate) || '01/01/1900'),
    );
    dispatch(changeMicrofInfoValue('coAppFullSsn', getAddedByAPIValue(coapp.ssn)));

    const { address } = coapp;
    if (address) {
      dispatch(
        changeMicrofInfoValue(
          'isSameAddress',
          isSameAddress(app.applicantPrimary.address, address),
        ),
      );
      dispatch(changeMicrofInfoValue('coAppStreet', address.street));
      dispatch(changeMicrofInfoValue('coAppCity', address.city));
      dispatch(changeMicrofInfoValue('coAppZipCode', address.zipcode));
      dispatch(changeMicrofInfoValue('coAppState', address.state));
    }
  }
};

export const setMicrofOnlyConsumerFlow = history => dispatch => {
  dispatch(resetNewAppState());
  dispatch(changePreQualInfoValue('isConsumer', true));
  dispatch(changeMicrofInfoValue('microfOnly', true));
  dispatch(setFlow(microfFlow));
  dispatch(changePage(MicrofFlowPageNumber.Landing));
  history.push('/RTO');
};

export const setMicrofOnlyConsumerSoftPullFlow = history => dispatch => {
  dispatch(resetNewAppState());
  dispatch(changeMicrofInfoValue('registerAsConsumer', true));
  dispatch(changePreQualInfoValue('isConsumer', true));
  dispatch(changeMicrofInfoValue('microfOnly', true));
  dispatch(changeAuthValue('aquaFinanceAssigned', false));
  dispatch(setFlow(defaultFlow));
  dispatch(changePage(AquaPreQualificationFlowPageNumber.AccountEmail));
  history.push('/');
};

export const uploadProofOfIncomeMicrofPartnerApplication = (
  applicantFiles,
  coApplicantFiles,
  history,
) => async (dispatch, getState) => {
  const {
    softPull: {
      applicationSubmitDTO: { applicationId, consumerToken, email },
    },
  } = getState();
  dispatch(startLoading());
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  let fd = createUploadRequestPayload(
    applicantFiles,
    coApplicantFiles,
    applicationId,
    email,
    consumerToken,
  );
  let post = false;
  if (!!applicantFiles[0]) {
    post = true;
  }
  if (post) {
    await axios
      .post(`${baseURL}/api/partner/application/documents`, fd, config)
      .then(() => dispatch(finishMicrofApplication(history)))
      .catch(error =>
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        ),
      );
  } else {
    dispatch(finishMicrofApplication(history));
  }
  dispatch(endLoading());
};

const createUploadRequestPayload = (
  applicantFiles,
  coApplicantFiles,
  applicationId,
  email,
  appToken,
) => {
  let fd = new FormData();

  fd.append('applicationId', applicationId);
  fd.append('email', email);

  if (appToken) {
    fd.append('appToken', appToken);
  }

  if (!!applicantFiles[0]) {
    fd.append('proposal', applicantFiles[0]);
  }

  if (!!applicantFiles[1]) fd.append('proposal2', applicantFiles[1]);
  if (!!applicantFiles[2]) fd.append('proposal3', applicantFiles[2]);
  if (!!applicantFiles[3]) fd.append('proposal4', applicantFiles[3]);
  if (!!applicantFiles[4]) fd.append('proposal5', applicantFiles[4]);

  if (!!coApplicantFiles && !!coApplicantFiles[0]) fd.append('coappProposal', coApplicantFiles[0]);
  if (!!coApplicantFiles && !!coApplicantFiles[1]) fd.append('coappProposal2', coApplicantFiles[1]);
  if (!!coApplicantFiles && !!coApplicantFiles[2]) fd.append('coappProposal3', coApplicantFiles[2]);
  if (!!coApplicantFiles && !!coApplicantFiles[3]) fd.append('coappProposal4', coApplicantFiles[3]);
  if (!!coApplicantFiles && !!coApplicantFiles[4]) fd.append('coappProposal5', coApplicantFiles[4]);

  return fd;
};

export const uploadProofOfIncomeMicrofApplication = (
  applicantFiles,
  coApplicantFiles,
  history,
) => async (dispatch, getState) => {
  const {
    softPull: {
      applicationSubmitDTO: { applicationId, email },
    },
  } = getState();
  dispatch(startLoading());

  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  let fd = createUploadRequestPayload(applicantFiles, coApplicantFiles, applicationId, email);

  let post = false;
  if (!!applicantFiles[0]) {
    post = true;
  }

  if (post) {
    await axios
      .post(`${baseURL}/api/soft_pull/documents`, fd, config)
      .then(response => {
        if (response.data) {
          dispatch(
            addNotification({
              message: response.data,
              messageType: 'Error',
            }),
          );
        } else {
          dispatch(changePage(MicrofFlowPageNumber.ApplicationReceived));
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
      });
  } else {
    dispatch(changePage(MicrofFlowPageNumber.ApplicationReceived));
  }
  dispatch(endLoading());
};

export const acceptTermsMicrofApplication = (applicationId, termAccepted, nextPage) => async (
  dispatch,
  getState,
) => {
  const {
    softPull: {
      applicationSubmitDTO: { selectedFinancier },
    },
  } = getState();
  dispatch(startLoading('Processing'));

  await axios
    .post(`${baseURL}/api/soft_pull/acceptTerms`, {
      applicationId: applicationId,
      termAccepted: termAccepted,
    })
    .then(async () => {
      if (isRheem && selectedFinancierEnum.RHEEM.value === selectedFinancier) {
        dispatch(changePage(RheemFlowPageNumber.FinalReview));
      } else {
        await dispatch(finalAcceptMicrofApplication(applicationId, nextPage));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const acceptTermsMicrofPartnerApplication = (
  applicationId,
  termAccepted,
  appToken,
) => async dispatch => {
  dispatch(startLoading('Processing'));

  await axios
    .post(`${baseURL}/api/partner/application/acceptTerms`, {
      applicationId: applicationId,
      termAccepted: termAccepted,
      appToken: appToken,
    })
    .then(async () => {
      await dispatch(finalAcceptMicrofPartnerApplication(applicationId, appToken));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const finalAcceptMicrofApplication = (applicationId, nextPage) => async dispatch => {
  dispatch(startLoading('Processing'));

  await axios
    .post(`${baseURL}/api/soft_pull/finalAccept`, qs.stringify({ applicationId: applicationId }))
    .then(response => dispatch(handleFinalAccept(response, nextPage)))
    .catch(error =>
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      ),
    );
  dispatch(endLoading());
};

export const finalAcceptMicrofPartnerApplication = (applicationId, appToken) => async dispatch => {
  dispatch(startLoading('Processing'));

  await axios
    .post(`${baseURL}/api/partner/application/finalAccept`, {
      applicationId: applicationId,
      appToken: appToken,
    })
    .then(response => dispatch(handleFinalAccept(response)))
    .catch(error =>
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      ),
    );
  dispatch(endLoading());
};

export const finishMicrofApplication = history => (dispatch, getState) => {
  const {
    auth: { user },
    microfInformation: { isFTL, isServiceFinance },
    softPull: { applicationSubmitDTO },
  } = getState();
  if (user?.dealerUser) {
    if (history) {
      history.push('/portal/dashboard');
      dispatch(resetNewAppState());
    } else {
      window.location.href = '/portal/dashboard';
    }
  } else if (
    user.internalUser &&
    (user.internalUser.role === userRoleEnum.DEALER_RELATIONSHIP_REP.value ||
      user.internalUser.role === userRoleEnum.USER_ADMINISTRATOR.value)
  ) {
    if (history) {
      history.push('/portal/manage-contractor-users');
    } else {
      window.location.href = '/portal/manage-contractor-users';
    }
  } else if (isFTL || applicationSubmitDTO?.addedBy === AddedByAPIEnum.FTL.name) {
    dispatch(changePage(MicrofFlowPageNumber.ApplicationFinished));
  } else if (
    isServiceFinance ||
    applicationSubmitDTO?.addedBy === AddedByAPIEnum.SERVICEFINANCE.name
  ) {
    dispatch(changePage(MicrofFlowPageNumber.ServiceFinanceComplete));
  } else {
    if (history) {
      history.push('/portal/consumer-dashboard');
    } else {
      window.location.href = '/portal/dashboard';
    }
  }
};

const handleFinalAccept = (response, nextPage, isPiOrPn) => dispatch => {
  dispatch(endLoading());
  const dealerId = parseInt(response.data?.dealer?.dealerId);

  const page = isPiOrPn ? MicrofFlowPageNumber.ManualProofOfIncome : nextPage;

  if (dealerId) {
    dispatch(changeAuthValue('dealerId', dealerId));
  }
  dispatch(
    changeSoftPullValue('appProcessingStatus', response.data?.application?.processingStatus),
  );
  dispatch({ type: MICROF_FINISHED, payload: response.data });
  dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });

  if (isRheem && selectedFinancierEnum.RHEEM.value === response.data.selectedFinancier) {
    dispatch(changePage(RheemFlowPageNumber.ContactInformation));
  } else {
    dispatch(changePage(page ? page : MicrofFlowPageNumber.ApplicationReceived));
  }
};

export const rejectMicrofApplication = (applicationId, props, fullreset) => async dispatch => {
  dispatch(startLoading('Processing'));

  await axios
    .post(`${baseURL}/api/soft_pull/reject`, qs.stringify({ applicationId: applicationId }))
    .then(response => {
      if (fullreset) {
        dispatch(resetState(props));
      } else {
        dispatch(resetNewAppState());
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const rejectMicrofPartnerApplication = (
  applicationId,
  props,
  appToken,
) => async dispatch => {
  dispatch(startLoading('Processing'));

  await axios
    .post(
      `${baseURL}/api/partner/application/reject`,
      qs.stringify({ applicationId: applicationId, appToken: appToken }),
    )
    .then(response => {
      dispatch(resetState(props));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const createConsumerUser = (params, page) => async dispatch => {
  dispatch(startLoading('Processing'));
  dispatch(changePreQualInfoValue('preQualInfoExists', false));

  await axios
    .post(`${baseURL}/api/soft_pull/save_consumer_user`, params)
    .then(response => {
      dispatch(changeAuthValue('consumerId', response.data.consumerUser.userId));
      dispatch(changeAuthValue('consumerUser', response.data.consumerUser));
      dispatch(
        loginConsumerUserAfterCreation({
          email: params.email,
          password: params.password,
          addedFrom: params.addedFrom,
          page: page,
        }),
      );
    })
    .catch(error => {
      dispatch(endLoading());
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.internalError &&
        error.response.data.internalError === 'USER_ALREADY_EXISTS'
      ) {
        dispatch(changePreQualInfoValue('preQualInfoExists', true));
      } else {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
      }
    });
};

export const updateConsumerUser = (params, page) => async dispatch => {
  dispatch(startLoading('Loading ...'));

  let response = await axios
    .post(`${baseURL}/api/soft_pull/update_consumer_user`, params)
    .then(updateConsumerUserResponse => {
      dispatch(changeAuthValue('consumerUser', updateConsumerUserResponse.data.consumerUser));
      if (page) {
        dispatch(changePage(page));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
  return response;
};

export const manualAcceptMicrofApplication = (applicationId, isPiOrPn) => async dispatch => {
  dispatch(startLoading('Processing'));

  await axios
    .post(`${baseURL}/api/soft_pull/manualAccept?applicationId=${applicationId}`)
    .then(response => dispatch(handleFinalAccept(response, null, isPiOrPn)))
    .catch(error =>
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      ),
    );
  dispatch(endLoading());
};

export const manualAcceptMicrofApplications = (
  applicationId,
  application2Id,
  isPiOrPn,
) => async dispatch => {
  dispatch(startLoading('Processing'));

  await axios
    .post(`${baseURL}/api/soft_pull/manualAccept?applicationId=${applicationId}`)
    .then(response => {
      if (application2Id) {
        axios
          .post(`${baseURL}/api/soft_pull/manualAccept?applicationId=${application2Id}`)
          .then(response2 => dispatch(handleFinalAccept(response2, null, isPiOrPn)))
          .catch(error =>
            dispatch(
              addNotification({
                message: handleApiError(error),
                messageType: 'Error',
              }),
            ),
          );
      }
      //dispatch(handleFinalAccept(response));
    })
    .catch(error =>
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
        dispatch(endLoading()),
      ),
    );
};

export const incomeValidation = (applicant, coApplicant, page) => async dispatch => {
  dispatch(startLoading('Processing'));

  /* uncomment to bypass test /*
  applicant.nameFirst = "CORY";
  applicant.nameLast = "B_EXP_NBK_700_201_754_10000";
  applicant.bankAccountNum = "56-4325647";
  applicant.bankAba = "999999963";
  if (!!coApplicant) {
    coApplicant.nameFirst = "CORY";
    coApplicant.nameLast = "B_EXP_NBK_700_201_754_10000";
    coApplicant.bankAccountNum = "56-4325647";
    coApplicant.bankAba = "999999963";
  }
  */

  let hasError = false;
  await axios
    .post(`${baseURL}/api/soft_pull/incomeValidation`, applicant)
    .then(response => {
      dispatch(changeMicrofInfoValue('appRequestCode', response.data.requestCode));
    })
    .catch(error => {
      hasError = true;
      dispatch(
        addNotification({
          message:
            'Failed to validate the income. Please check your account information. If there is an issue validating income, please click on the arrow on the left once to go back to the Proof of income screen choices and select the THE MANUAL METHOD.',
          messageType: 'Error validating the income for Applicant',
        }),
      );
    });
  if (!hasError && coApplicant != null) {
    await axios
      .post(`${baseURL}/api/soft_pull/incomeValidation`, coApplicant)
      .then(response => {
        dispatch(changeMicrofInfoValue('coAppRequestCode', response.data.requestCode));
      })
      .catch(error => {
        hasError = true;
        dispatch(
          addNotification({
            message:
              'Failed to validate the income. Please check your account information. If there is an issue validating income, please click on the arrow on the left once to go back to the Proof of income screen choices and select the THE MANUAL METHOD.',
            messageType: 'Error validating the income for Co-Applicant',
          }),
        );
      });
  }
  if (!hasError) {
    dispatch(changePage(page));
  }
  dispatch(endLoading());
};

export const incomeValidationRequestCompleted = (data, coApplicantId, page) => async dispatch => {
  dispatch(startLoading('Processing'));

  let isOK = false;
  await axios
    .post(`${baseURL}/api/soft_pull/incomeValidationRequestCompleted`, data)
    .then(response => {
      isOK = response.data;
    })
    .catch(error => {
      isOK = false;
    });

  if (!isOK) {
    dispatch(
      addNotification({
        message: 'Please finish the income validation process!',
        messageType: 'Error',
      }),
    );
  } else if (isOK) {
    dispatch(changePage(page));
  }
  dispatch(endLoading());
};
